
#history {
    position: relative;
    z-index: 1; /* Asegura que los elementos dentro del contenedor estén sobre el background */
  }
  
  #history::before {
    content: "";
    background-image: url("../img/background-nosotros.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.1; /* Ajusta la opacidad según lo que prefieras */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; /* Envía la imagen detrás de los elementos del contenido */
  }