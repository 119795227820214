
.color-gradient{
  background: linear-gradient(to left, #336699, #7FDBDA);
  border: none;
}

.color-gradient:hover{
  background: #333333;
}

.primary-color-text{
  color: #7FDBDA;
}

h3{
  background: linear-gradient(to right, #336699, #7FDBDA); /* Degradado para el texto */
  -webkit-background-clip: text; /* Solo afecta al texto en navegadores basados en WebKit */
  background-clip: text; /* Aplica al texto en navegadores que soportan la propiedad */
  color: transparent; /* Hace que el color sólido del texto sea transparente para mostrar el degradado */
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
