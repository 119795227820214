.icon{
    width: 50px;
    height: 50px;
  }

  .b-hover{
    border:solid;
    border-color: rgb(236, 231, 231);
    border-width: 3px;
  }
  
  .b-hover:hover{
    border-color:  #336699;;
  }