body{
    height: 100vh; 
}


.color-gradient {
    background: linear-gradient(to left, #336699, #7FDBDA);
    border: none;
}

.color-gradient:hover {
    background: #333333;
}

h3 {
    background: linear-gradient(to right, #336699, #7FDBDA);
    /* Degradado para el texto */
    -webkit-background-clip: text;
    /* Solo afecta al texto en navegadores basados en WebKit */
    background-clip: text;
    /* Aplica al texto en navegadores que soportan la propiedad */
    color: transparent;
    /* Hace que el color sólido del texto sea transparente para mostrar el degradado */
}

#login {
    position: relative;
    z-index: 1;
    /* Asegura que los elementos dentro del contenedor estén sobre el background */
    width: 100%; /* O ajusta a las dimensiones que necesitas */
    height: auto; /* Asegúrate de que el contenedor tenga una altura definida */
    overflow: hidden; /* Evita que el contenido se desborde fuera de los márgenes */
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

#login::before {
    content: "";
    background-image: url('../img/background-create_acount.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.1;
    /* Ajusta la opacidad según lo que prefieras */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    /* Envía la imagen detrás de los elementos del contenido */
}

.primary-color-text {
    color: #7FDBDA;
}

.icon {
    width: 50px;
    height: 50px;
}

.nav-secondary-item {
    color: white;
}

.nav-secondary-item:hover {
    color: #7FDBDA;
}

.nav-primary-item {
    color: #7FDBDA;
}

.nav-primary-item:hover {
    color: #336699;
}


#register {
    position: relative;
    z-index: 1;
    /* Asegura que los elementos dentro del contenedor estén sobre el background */
}

#register::before {
    content: "";
    background-image: url("../img/background-create_acount.jpeg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* opacity: 0.1; */
    /* Ajusta la opacidad según lo que prefieras */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    /* Envía la imagen detrás de los elementos del contenido */
    
}

#register::after {
    content: "";
    background-color: rgb(51, 51, 51,0.9); /* Capa oscura */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; /* Mantén la capa detrás del contenido */
}